import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, Grid } from '@mui/material';
import { getFileCategoryAnalytics } from 'src/services/analytics.service';
import StackedHorizontalBarChart from 'src/sections/@dashboard/app/StackedHorizontalBarChart';

const color_map = {
    'office': '#5564aa',
    'media': '#94cd81',
    'code': '#FF4560',
    'design': '#5b5b5c',
    'archive': '#FF4560',
    'font': '#FF4560',
    'other': '#FF4560',
}

class FileCategoryAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            by_count: [],
            by_size: [],
        }
    }

    async componentDidMount() {
        let file_category_analytics = await getFileCategoryAnalytics();
        
        if (file_category_analytics.error) {
            console.error(file_category_analytics.error);
            return;
        }
        file_category_analytics = file_category_analytics.file_category_analytics;

        let by_count_array = file_category_analytics.by_count || [];
        let by_size_array = file_category_analytics.by_size || [];

        let by_count = [];
        let by_size = [];

        for(let i = 0; i < by_count_array.length; i++) {
            let item = by_count_array[i];
            if (i >= 10) {
                break;
            }
            by_count.push({
                name: item.file_category,
                data: [item.count],
                color: color_map[item.file_category] || color_map['other']
            });
        }

        for(let i = 0; i < by_size_array.length; i++) {
            let item = by_size_array[i];
            if (i >= 10) {
                break;
            }
            by_size.push({
                name: item.file_category,
                data: [item.size / 1024 / 1024],
                color: color_map[item.file_category] || color_map['other']
            });
        }

        this.setState({
            by_count: by_count,
            by_size: by_size
        });

    }

    render() {

        return (
            <>
                <Card variant='outlined'>
                    <CardHeader title='Files Categories' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <StackedHorizontalBarChart
                                series={this.state.by_count}
                                labels={['Count']}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StackedHorizontalBarChart
                                series={this.state.by_size}
                                labels={['Size']}
                                round={2}
                                unit='MB'
                            />
                        </Grid>
                    </Grid>
                </Card>
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <FileCategoryAnalytics {...props} navigate={navigate} params={params} />;
}
