import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getFileTypeAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_type' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFileCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFileSubCategoryAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/file_sub_category' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getFlaggedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/flagged' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getOrganizedAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/organized' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getSizeAnalytics(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/analytics/size' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

export {
  getFileTypeAnalytics,
  getFileCategoryAnalytics,
  getFileSubCategoryAnalytics,
  getFlaggedAnalytics,
  getOrganizedAnalytics,
  getSizeAnalytics
}
